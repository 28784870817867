// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";

import "./Checkbox.css"
;

function input(id, onChange, checked, disabled) {
  return React.createElement("input", {
              className: "checkbox__input",
              id: id,
              checked: checked,
              disabled: disabled,
              type: "checkbox",
              onChange: onChange
            });
}

function Checkbox(Props) {
  var id = Props.id;
  var onChange = Props.onChange;
  var checkedOpt = Props.checked;
  var label = Props.label;
  var disabledOpt = Props.disabled;
  var checked = checkedOpt !== undefined ? checkedOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return React.createElement("div", {
              className: "relative"
            }, label !== undefined ? React.createElement("label", {
                    className: "checkbox__label flex items-center space-x-2",
                    htmlFor: id
                  }, input(id, onChange, checked, disabled), React.createElement("div", {
                        className: "text-sm flex-1"
                      }, Caml_option.valFromOption(label))) : React.createElement("label", {
                    className: "checkbox__label flex items-center space-x-2",
                    htmlFor: id
                  }, input(id, onChange, checked, disabled)));
}

var make = Checkbox;

export {
  input ,
  make ,
  
}
/*  Not a pure module */
