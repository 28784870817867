// Generated by ReScript, PLEASE EDIT WITH CARE


function setReloading(t) {
  if (!t) {
    return /* Reloading */{
            _0: [true]
          };
  }
  var times = t._0;
  times.push(true);
  return /* Reloading */{
          _0: times
        };
}

function setNotLoading(t) {
  if (!t) {
    return /* Reloading */{
            _0: []
          };
  }
  var times = t._0;
  times.pop();
  return /* Reloading */{
          _0: times
        };
}

function empty(param) {
  return /* Reloading */{
          _0: []
        };
}

export {
  setReloading ,
  setNotLoading ,
  empty ,
  
}
/* No side effect */
