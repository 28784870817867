// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../../../node_modules/@glennsl/bs-json/src/Json.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";

var DataElementMissing = /* @__PURE__ */Caml_exceptions.create("DomUtils.DataElementMissing");

var RootElementMissing = /* @__PURE__ */Caml_exceptions.create("DomUtils.RootElementMissing");

var RootAttributeMissing = /* @__PURE__ */Caml_exceptions.create("DomUtils.RootAttributeMissing");

function parseJSONTag(idOpt, param) {
  var id = idOpt !== undefined ? idOpt : "react-root-data";
  var rootElement = document.getElementById(id);
  var tmp;
  if (rootElement == null) {
    throw {
          RE_EXN_ID: DataElementMissing,
          _1: id,
          Error: new Error()
        };
  }
  tmp = rootElement.innerHTML;
  return Json.parseOrRaise(tmp);
}

function parseJSONAttribute(idOpt, attributeOpt, param) {
  var id = idOpt !== undefined ? idOpt : "react-root";
  var attribute = attributeOpt !== undefined ? attributeOpt : "data-json-props";
  var rootElement = document.getElementById(id);
  var tmp;
  if (rootElement == null) {
    throw {
          RE_EXN_ID: RootElementMissing,
          _1: id,
          Error: new Error()
        };
  }
  var props = rootElement.getAttribute(attribute);
  if (props == null) {
    throw {
          RE_EXN_ID: RootAttributeMissing,
          _1: attribute,
          Error: new Error()
        };
  }
  tmp = props;
  return Json.parseOrRaise(tmp);
}

function redirect(path) {
  window.location = path;
  
}

function reload(param) {
  window.location.reload();
  
}

function isDevelopment(param) {
  var props = document.documentElement.getAttribute("data-env");
  if (props == null) {
    return false;
  } else {
    return props === "development";
  }
}

function goBack(param) {
  window.history.back();
  
}

function getUrlParam(key) {
  return Caml_option.nullable_to_opt(new URLSearchParams(window.location.search).get(key));
}

function hasUrlParam(key) {
  return Belt_Option.isSome(getUrlParam(key));
}

var $$FormData = {};

var $$EventTarget = {};

var $$Event = {};

function clearFileInput(inputId, callBack, param) {
  var e = document.getElementById(inputId);
  if (!(e == null)) {
    e.value = "";
  }
  return Belt_Option.mapWithDefault(callBack, undefined, (function (cb) {
                return Curry._1(cb, undefined);
              }));
}

var $$Element = {
  clearFileInput: clearFileInput
};

export {
  DataElementMissing ,
  RootElementMissing ,
  RootAttributeMissing ,
  parseJSONTag ,
  parseJSONAttribute ,
  redirect ,
  reload ,
  isDevelopment ,
  goBack ,
  getUrlParam ,
  hasUrlParam ,
  $$FormData ,
  $$EventTarget ,
  $$Event ,
  $$Element ,
  
}
/* No side effect */
