// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spread from "./Spread.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as WindowUtils from "../utils/WindowUtils.bs.js";
import * as RescriptReactRouter from "../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";

function ctrlKey(prim) {
  return prim.ctrlKey;
}

function metaKey(prim) {
  return prim.metaKey;
}

function onConfirm(href, onClick, $$event) {
  $$event.preventDefault();
  Belt_Option.mapWithDefault(onClick, undefined, (function (onClick) {
          return Curry._1(onClick, $$event);
        }));
  return RescriptReactRouter.push(href);
}

function onCancel($$event) {
  $$event.preventDefault();
  
}

function handleOnClick(href, confirm, onClick, $$event) {
  var modifierPressed = $$event.ctrlKey || $$event.metaKey;
  if (modifierPressed) {
    return ;
  } else if (confirm !== undefined) {
    return WindowUtils.confirm((function (param) {
                  $$event.preventDefault();
                  
                }), confirm, (function (param) {
                  return onConfirm(href, onClick, $$event);
                }));
  } else {
    return onConfirm(href, onClick, $$event);
  }
}

function link(href, includeSearch) {
  var search = window.location.search;
  if (includeSearch) {
    return href + search;
  } else {
    return href;
  }
}

function Link(Props) {
  var href = Props.href;
  var ariaLabel = Props.ariaLabel;
  var className = Props.className;
  var confirm = Props.confirm;
  var id = Props.id;
  var onClick = Props.onClick;
  var title = Props.title;
  var children = Props.children;
  var includeSearchOpt = Props.includeSearch;
  var disabledOpt = Props.disabled;
  var props = Props.props;
  var includeSearch = includeSearchOpt !== undefined ? includeSearchOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var switchProps = Belt_Option.getWithDefault(props, {});
  var partial_arg = link(href, includeSearch);
  var tmp = {
    disabled: disabled,
    href: link(href, includeSearch),
    onClick: (function (param) {
        return handleOnClick(partial_arg, confirm, onClick, param);
      })
  };
  if (ariaLabel !== undefined) {
    tmp["aria-label"] = Caml_option.valFromOption(ariaLabel);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  return React.createElement(Spread.make, {
              props: switchProps,
              children: React.createElement("a", tmp, children)
            });
}

var make = Link;

export {
  ctrlKey ,
  metaKey ,
  onConfirm ,
  onCancel ,
  handleOnClick ,
  link ,
  make ,
  
}
/* react Not a pure module */
