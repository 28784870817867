// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as OptionUtils from "./OptionUtils.bs.js";

function confirm(onCancel, message, f) {
  if (window.confirm(message)) {
    return Curry._1(f, undefined);
  } else {
    return OptionUtils.mapWithDefault((function (onCancel) {
                  return Curry._1(onCancel, undefined);
                }), undefined, onCancel);
  }
}

export {
  confirm ,
  
}
/* No side effect */
