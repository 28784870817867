// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ArrayUtils from "../utils/ArrayUtils.bs.js";
import * as LevelLabel from "../utils/LevelLabel.bs.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          number: Json_decode.field("number", Json_decode.$$int, json)
        };
}

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function number(t) {
  return t.number;
}

function sort(levels) {
  return ArrayUtils.copyAndSort((function (x, y) {
                return x.number - y.number | 0;
              }), levels);
}

function unsafeLevelNumber(levels, componentName, levelId) {
  return LevelLabel.format(undefined, undefined, String(ArrayUtils.unsafeFind((function (l) {
                        return l.id === levelId;
                      }), "Unable to find level with id: " + (levelId + componentName), levels).number));
}

function make(id, name, number) {
  return {
          id: id,
          name: name,
          number: number
        };
}

function makeFromJs(level) {
  return {
          id: level.id,
          name: level.name,
          number: level.number
        };
}

function shortName(t) {
  return "Level " + String(t.number);
}

function filterValue(t) {
  return t.id + ";" + String(t.number) + ", " + t.name;
}

var Raw = {};

function parse(value) {
  return {
          id: value.id,
          name: value.name,
          number: value.number
        };
}

function serialize(value) {
  var value$1 = value.number;
  var value$2 = value.name;
  var value$3 = value.id;
  return {
          id: value$3,
          name: value$2,
          number: value$1
        };
}

function verifyArgsAndParse(_LevelFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var Fragment = {
  Raw: Raw,
  query: "fragment LevelFragment on Level   {\nid  \nname  \nnumber  \n}\n",
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

function makeFromFragment(level) {
  return {
          id: level.id,
          name: level.name,
          number: level.number
        };
}

export {
  decode ,
  id ,
  name ,
  number ,
  sort ,
  unsafeLevelNumber ,
  make ,
  makeFromJs ,
  shortName ,
  filterValue ,
  Fragment ,
  makeFromFragment ,
  
}
/* ArrayUtils Not a pure module */
