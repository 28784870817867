// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function linkTitle(link) {
  return link.title;
}

function linkUrl(link) {
  return link.url;
}

function decodeLink(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          url: Json_decode.field("url", Json_decode.string, json)
        };
}

function localLinks(t) {
  return [
            "Admin",
            "Dashboard",
            "Coaches"
          ].includes(t.title);
}

function name(t) {
  return t.name;
}

function logoUrl(t) {
  return t.logoUrl;
}

function links(t) {
  return t.links;
}

function iconUrl(t) {
  return t.iconUrl;
}

function coverImageUrl(t) {
  return t.coverImageUrl;
}

function decode(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          logoUrl: Caml_option.null_to_opt(Json_decode.field("logoUrl", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json)),
          coverImageUrl: Caml_option.null_to_opt(Json_decode.field("coverImageUrl", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json)),
          links: Json_decode.field("links", (function (param) {
                  return Json_decode.array(decodeLink, param);
                }), json),
          iconUrl: Json_decode.field("iconUrl", Json_decode.string, json)
        };
}

export {
  linkTitle ,
  linkUrl ,
  decodeLink ,
  localLinks ,
  name ,
  logoUrl ,
  links ,
  iconUrl ,
  coverImageUrl ,
  decode ,
  
}
/* No side effect */
