// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function str(prim) {
  return prim;
}

function School__InputGroupError(Props) {
  var message = Props.message;
  var active = Props.active;
  var warnOpt = Props.warn;
  var warn = warnOpt !== undefined ? warnOpt : false;
  if (!active) {
    return null;
  }
  var colors = warn ? "text-orange-600 bg-orange-100" : "text-red-600 bg-red-100";
  return React.createElement("div", {
              className: "mt-1 px-1 py-px rounded text-xs font-semibold inline-flex items-center " + colors
            }, React.createElement("span", {
                  className: "mr-2"
                }, React.createElement("i", {
                      className: "fas fa-exclamation-triangle"
                    })), React.createElement("span", undefined, message));
}

var make = School__InputGroupError;

export {
  str ,
  make ,
  
}
/* react Not a pure module */
