// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Fetch from "../../../../node_modules/bs-fetch/src/Fetch.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as $$Notification from "../Notification.bs.js";
import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as AuthenticityToken from "./AuthenticityToken.bs.js";

function Extender(M) {
  var Graphql_error = /* @__PURE__ */Caml_exceptions.create("GraphqlQuery.Extender(M).Graphql_error");
  var decodeNotification = function (json) {
    return {
            kind: Json_decode.field("kind", Json_decode.string, json),
            title: Json_decode.field("title", Json_decode.string, json),
            body: Json_decode.field("body", Json_decode.string, json)
          };
  };
  var decodeObject = function (json) {
    return Promise.resolve(json);
  };
  var decodeNotifications = function (json) {
    return Json_decode.list(decodeNotification, json);
  };
  var decodeErrors = function (json) {
    return Json_decode.array((function (param) {
                  return Json_decode.field("message", Json_decode.string, param);
                }), json);
  };
  var flashNotifications = function (obj) {
    var notifications = Js_dict.get(obj, "notifications");
    if (notifications !== undefined) {
      return List.iter((function (n) {
                    var match = n.kind;
                    var notify;
                    switch (match) {
                      case "error" :
                          notify = $$Notification.error;
                          break;
                      case "success" :
                          notify = $$Notification.success;
                          break;
                      default:
                        notify = $$Notification.notice;
                    }
                    return Curry._2(notify, n.title, n.body);
                  }), Json_decode.list(decodeNotification, Caml_option.valFromOption(notifications)));
    }
    
  };
  var sendQuery = function (notifyOpt, notifyOnNotFoundOpt, query, variables) {
    var notify = notifyOpt !== undefined ? notifyOpt : true;
    var notifyOnNotFound = notifyOnNotFoundOpt !== undefined ? notifyOnNotFoundOpt : true;
    return fetch("/graphql", Fetch.RequestInit.make(/* Post */2, [
                          [
                            "X-CSRF-Token",
                            AuthenticityToken.fromHead(undefined)
                          ],
                          [
                            "Content-Type",
                            "application/json"
                          ]
                        ], Caml_option.some(JSON.stringify(Js_dict.fromList({
                                      hd: [
                                        "query",
                                        query
                                      ],
                                      tl: {
                                        hd: [
                                          "variables",
                                          variables
                                        ],
                                        tl: /* [] */0
                                      }
                                    }))), undefined, undefined, undefined, /* Include */2, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (resp) {
                  if (resp.ok) {
                    return resp.json();
                  }
                  if (notify) {
                    var statusCode = String(resp.status);
                    if (notifyOnNotFound) {
                      $$Notification.error("Error " + statusCode, "Our team has been notified of this error. Please reload the page and try again.");
                    }
                    
                  }
                  return Promise.reject({
                              RE_EXN_ID: Graphql_error,
                              _1: "Request failed: " + resp.statusText
                            });
                }).then(function (json) {
                var obj = Js_json.decodeObject(json);
                if (obj === undefined) {
                  return Promise.reject({
                              RE_EXN_ID: Graphql_error,
                              _1: "Response is not an object"
                            });
                }
                var obj$1 = Caml_option.valFromOption(obj);
                if (notify) {
                  flashNotifications(obj$1);
                }
                var errors = Js_dict.get(obj$1, "errors");
                if (errors !== undefined) {
                  console.log(json);
                  decodeErrors(Caml_option.valFromOption(errors)).forEach(function (e) {
                        return $$Notification.error("Error", e);
                      });
                  return Promise.reject({
                              RE_EXN_ID: Graphql_error,
                              _1: "Something went wrong!"
                            });
                } else {
                  return Promise.resolve(obj$1["data"]);
                }
              });
  };
  var query = function (notify, notifyOnNotFound, variables) {
    return sendQuery(notify, notifyOnNotFound, M.query, Curry._1(M.variablesToJson, Curry._1(M.serializeVariables, variables)));
  };
  var $$fetch$1 = function (notifyOpt, notifyOnNotFoundOpt, variables) {
    var notify = notifyOpt !== undefined ? notifyOpt : true;
    var notifyOnNotFound = notifyOnNotFoundOpt !== undefined ? notifyOnNotFoundOpt : true;
    return query(notify, notifyOnNotFound, variables).then(function (data) {
                return Promise.resolve(Curry._1(M.parse, data));
              });
  };
  var make = function (notifyOpt, notifyOnNotFoundOpt, variables) {
    var notify = notifyOpt !== undefined ? notifyOpt : true;
    var notifyOnNotFound = notifyOnNotFoundOpt !== undefined ? notifyOnNotFoundOpt : true;
    return query(notify, notifyOnNotFound, variables).then(function (data) {
                return Promise.resolve(Curry._1(M.parse, data));
              });
  };
  return {
          Graphql_error: Graphql_error,
          decodeNotification: decodeNotification,
          decodeObject: decodeObject,
          decodeNotifications: decodeNotifications,
          decodeErrors: decodeErrors,
          flashNotifications: flashNotifications,
          sendQuery: sendQuery,
          query: query,
          $$fetch: $$fetch$1,
          make: make
        };
}

export {
  Extender ,
  
}
/* Notification Not a pure module */
