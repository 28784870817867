// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function title(t) {
  return t.title;
}

function isAdmin(t) {
  return t.isAdmin;
}

function canEditProfile(t) {
  return t.canEditProfile;
}

function avatarUrl(t) {
  return t.avatarUrl;
}

function hasNotifications(t) {
  return t.hasNotifications;
}

function coachId(t) {
  return t.coachId;
}

function isAuthor(t) {
  return t.isAuthor;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json),
          isAdmin: Json_decode.field("isAdmin", Json_decode.bool, json),
          canEditProfile: Json_decode.field("canEditProfile", Json_decode.bool, json),
          avatarUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("avatarUrl", Json_decode.string, param);
                }), json),
          hasNotifications: Json_decode.field("hasNotifications", Json_decode.bool, json),
          coachId: Json_decode.optional((function (param) {
                  return Json_decode.field("coachId", Json_decode.string, param);
                }), json),
          isAuthor: Json_decode.field("isAuthor", Json_decode.bool, json)
        };
}

function empty(param) {
  return {
          id: "",
          name: "Unknown User",
          title: "Unknown",
          isAdmin: false,
          canEditProfile: false,
          avatarUrl: undefined,
          hasNotifications: false,
          coachId: undefined,
          isAuthor: false
        };
}

function defaultUser(currentUser) {
  return Belt_Option.getWithDefault(currentUser, {
              id: "",
              name: "Unknown User",
              title: "Unknown",
              isAdmin: false,
              canEditProfile: false,
              avatarUrl: undefined,
              hasNotifications: false,
              coachId: undefined,
              isAuthor: false
            });
}

export {
  id ,
  name ,
  title ,
  isAdmin ,
  canEditProfile ,
  avatarUrl ,
  hasNotifications ,
  coachId ,
  isAuthor ,
  decode ,
  empty ,
  defaultUser ,
  
}
/* No side effect */
